import { Contents, Background } from "../styles/ContentPage";
import Navbar from "../componentes/Navbar";
import Footer from "../componentes/Footer";
import { useNavigate } from "react-router-dom";
import { BsCheckLg, BsFillBrightnessAltHighFill } from "react-icons/bs";
import { FaHandPointDown } from "react-icons/fa";
import { GiRotaryPhone } from "react-icons/gi";
import { BiWorld } from "react-icons/bi";

const SaleHalp = () => {
  const navigate = useNavigate();
  const onChangeHandle = () => {
    navigate("/");
  };
  window.scrollTo(0, 0)
  return (
    <Background>
      <Navbar onChangeHandle={onChangeHandle} />
      <div className="container-sm">
        <Contents>
          <label className="label-header">
            มัดรวมระบบ HR และระบบ Sale ไว้ในที่เดียวที่ 'SaleServe'
          </label>
          <div className="content">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/Saleserv-June-04-01.jpg"}
                alt={"มัดรวมระบบ HR และระบบ Sale ไว้ในที่เดียวที่ SaleServe"}
              />
            </div>
            <div>
              <label style={{ marginBottom: 15 }}>
                SaleServ ซอฟต์แวร์เพื่อการบริหารจัดการ 'ทีมขาย' และ 'งานขาย' ให้มีประสิทธิภาพ
              </label>
              <label style={{ marginBottom: 15 }}>
                มัดรวมระบบ HR และระบบ Sale ไว้ในที่เดียว พัฒนาธุรกิจอย่างไม่หยุดยั้งด้วยเทคโนโลยีใหม่ๆ ที่ถูกพัฒนาเพื่อระบบ POS (Point of Sale) เพื่อช่วยอำนวยความสะดวกในการบริหารจัดการการขายหน้าร้านค้าปลีกเพื่อให้ตอบโจทย์ทุกความต้องการ
              </label>
              <label><BsCheckLg color="green" />  ลดขั้นตอนการทำงาน’ โดยใช้ระบบออนไลน์ </label>
              <label><BsCheckLg color="green" />  เก็บและจัดการข้อมูล’ ของสินค้าใน Stock </label>
              <label><BsCheckLg color="green" />  ลดความผิดพลาด’ ที่อาจเกิดขึ้น </label>
              <label><BsCheckLg color="green" />  ประหยัดเวลา’ ในการคำนวณราคาสินค้าและค่าคอมมิชชัน </label>
              <label style={{ marginBottom: 15 }}><BsCheckLg color="green" />  ติดตามการทำงาน’ ของพนักงานเพื่อความปลอดภัยในการเข้าถึงข้อมูล </label>
              <label style={{ marginBottom: 15 }}>
                หากคุณมีธุรกิจที่อาศัยการจัดการคลังสินค้า พนักงาน หรือร้านค้าปลีกต่างๆ ‘SaleServ’ เครื่องมือในการสร้างระบบการทำงาน จัดระเบียบข้อมูล รายงานแบบเรียลไทม์ และประหยัดเวลา คือคำตอบเพื่อการดำเนินธุรกิจของคุณ
              </label>
              <label style={{ marginBottom: 15 }}>
                Believe in technology, Believe in us.
              </label>

              <label>
                ติดต่อสอบถามเพิ่มเติมได้ที่ <FaHandPointDown color="#d1ad8e" />
              </label>
              <label>
                <BsFillBrightnessAltHighFill /> Inbox : m.me/SaleServ.co
              </label>
              <label>
                <GiRotaryPhone color="#b50d1b" /> Tel : 02-0072421
              </label>
              <label style={{ marginBottom: 15 }}>
                <BiWorld color="#0fad4c" /> Website:{" "}
                <a href=" https://SaleServ.co">https://SaleServ.co</a>
              </label>
              <label>
                #SaleServ #เว็บไซต์ #การจัดการทีมขาย #BetterSalesBetterService
              </label>
            </div>
          </div>
        </Contents>
      </div>
      <Footer />
    </Background>
  );
};

export default SaleHalp;
