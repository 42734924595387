import { Contents, Background } from "../styles/ContentPage";
import Navbar from "../componentes/Navbar";
import Footer from "../componentes/Footer";
import { useNavigate } from "react-router-dom";
import { BsFillBrightnessAltHighFill, BsStars } from "react-icons/bs";
import { FaHandPointDown } from "react-icons/fa";
import { GiRotaryPhone } from "react-icons/gi";
import { BiWorld } from "react-icons/bi";

const ManageSale = () => {
  const navigate = useNavigate();
  const onChangeHandle = () => {
    navigate("/");
  };
  window.scrollTo(0, 0)
  return (
    <Background>
      <Navbar onChangeHandle={onChangeHandle} />
      <div className="container-sm">
        <Contents>
          <label className="label-header">
            ทำความรู้จักกับ SaleServ เครื่องมือบริหารการขายสำหรับบริษัทยุคใหม่
          </label>
          <div className="content">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/Saleserv-June-03-01.jpg"}
                alt={"คำนวณ Commission ทันที อัปเดตยอดขาย Real - timeSaleServ เครื่องมือบริหารการขายสำหรับบริษัทยุคใหม่"}
              />
            </div>
            <div>
              <label style={{ marginBottom: 15 }}>
                บริหารจัดการทีมขายได้อย่างสะดวก รวดเร็ว ผ่านระบบออนไลน์ด้วย 'SaleServ' ระบบการบริหารและจัดการการขายหน้าร้านค้าปลีกที่สามารถเก็บข้อมูลยอดขาย, ตรวจสอบสต็อกสินค้า และคำนวณค่าคอมมิชชันเพื่อวางแผนโปรโมชั่นการตลาดและพัฒนากลยุทธ์การขาย
              </label>
              <label style={{ marginBottom: 15 }}>
                <BsStars color="#e0d300" /> จุดเด่นของ 'SaleServ' <BsStars color="#e0d300" />
              </label>
              <label style={{ marginBottom: 15 }}>
                โปรแกรม SaleServ ถูกออกแบบอย่างครบครันด้วยเครื่องมือสำคัญที่ทีมขายต้องใช้ทำงานเป็นประจำ ตั้งแต่บันทึกรายงาน ติดตามผลการขาย คำนวณค่าคอมมิชชัน ตรวจสอบและจัดการสต๊อกสินค้า แสดงยอดขายสินค้าแต่ละประเภท ระบุตำแหน่งร้านค้า รับการสั่งซื้อบนแอปพลิเคชันเพื่อลดการใช้กระดาษ นอกจากนี้ยังแสดงยอดขายของพนักงานขายรายบุคคลเพื่อดูศักยภาพการขาย ตรวจสอบเวลาทำงาน ซึ่งสามารถดาวน์โหลดข้อมูลต่างๆ เก็บไว้ในรูปแบบ Offline เพื่อนำไปใช้ในการวางแผนพัฒนากลยุทธ์ในการขายได้อีกด้วย
              </label>
              <label style={{ marginBottom: 15 }}>
                ปิดยอดขาย ไม่ยาก! เพียงแค่มีตัวช่วยในการพัฒนา 'ระบบการจัดการการขาย' ให้เกิดประสิทธิภาพสูงสุด ให้ SaleServ ช่วยธุรกิจของคุณเติบโตอย่างมั่นคง ก้าวผ่านคู่แข่งอย่างมั่นใจ
              </label>
              <label>
                Better Sales, Better Service
              </label>
              <label style={{ marginBottom: 15 }}>
                เครื่องมือช่วยเพิ่มประสิทธิภาพในการบริหารจัดการทีมขายและยอดขาย
              </label>

              <label>
                ติดต่อสอบถามเพิ่มเติมได้ที่ <FaHandPointDown color="#d1ad8e" />
              </label>
              <label>
                <BsFillBrightnessAltHighFill /> Inbox : m.me/SaleServ.co
              </label>
              <label>
                <GiRotaryPhone color="#b50d1b" /> Tel : 02-0072421
              </label>
              <label style={{ marginBottom: 15 }}>
                <BiWorld color="#0fad4c" /> Website:{" "}
                <a href=" https://SaleServ.co">https://SaleServ.co</a>
              </label>
              <label>
                #SaleServ #เว็บไซต์ #การจัดการทีมขาย #BetterSalesBetterService
              </label>
            </div>
          </div>
        </Contents>
      </div>
      <Footer />
    </Background>
  );
};

export default ManageSale;
