import { Background, Contents } from "../styles/BannerStyle";

const Banner = () => {
  return (
    <Background>
      <div className="container-sm">
        <Contents>
          <div>
            <button>WELLCOME</button>
            <label className="label-en">SaleServ</label>
            <div className="label-top">
              <label>เครื่องมือ</label>
              <label>บริหารการขาย</label>
            </div>
            <div className="label-bottom">
              <label>สำหรับ</label>
              <label>บริษัทยุคใหม่</label>
            </div>

            <span style={{ fontFamily: "Mitr" }}>
              เราพัฒนาและออกแบบมาเพื่อให้การจัดการการขาย
              และบริหารทีมขายได้อย่างสะดวก มีประสิทธิภาพมากยิ่งขึ้น
              ช่วยให้การวางแผน และการจัดการธุรกิจเติบโตทันยุคสมัย ในปัจจุบัน
              มาพร้อม Function ที่ตอบโจทย์เรื่องการขาย
            </span>
          </div>
          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/LT-1.png"}
              alt={process.env.PUBLIC_URL + "/images/LT-1.png"}
              className="img-lt"
            />
          </div>
        </Contents>
      </div>
    </Background>
  );
};

export default Banner;
