import {
  Background,
  Contents,
  CardBody,
  SwiperDesktop,
  SwiperMoblie,
} from "../styles/ArticlesStyle";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { Link } from "react-router-dom";

import "swiper/css";
import "swiper/css/pagination";

const Articles = () => {
  const pagination = {
    clickable: true,
    // renderBullet: function (index, className) {
    //   return '<span class="' + className + '">' + (index + 1) + "</span>";
    // },
  };

  return (
    <Background>
      <div className="container-sm">
        <Contents>
          <div>
            <label className="label-top">Articles</label>
            <label className="label-center">
              ปิดยอดขาย ไม่ยาก เพียงแค่มี 'ระบบการจัดการการขาย' ที่ดี
            </label>
            <label className="label-bottom">
              ทีมขาย มีผลโดยตรงต่อรายได้ขององค์กร
              การมีตัวช่วยในการพัฒนาระบบถือเป็นสิ่งสำคัญที่ทำให้การวางกลยุทธ์การขายมีประสิทธิภาพสูงสุด
            </label>
          </div>

          <SwiperDesktop>
            <Swiper
              pagination={pagination}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide>
                <CardBody>
                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-May-P1-01.jpg"
                        }
                        alt={"เชื่อมโยงทุกหน้าที่ สร้างงานขายที่ดีกว่า"}
                      />
                      <div className="crad-body-date">May 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>
                        Better Sale Better Service
                        เครื่องมือช่วยเพิ่มประสิทธิภาพในการบริหารจัดการทีมขายและยอดขาย
                      </label>
                      <span>
                        'SaleServ' เพิ่มประสิทธิภาพการบริหารจัดการทีมขาย
                        เสริมสร้างยอดขายอย่างก้าวกระโดด
                      </span>
                      <Link to="/articles-better-sale-better-service">
                        อ่านต่อ {">"}
                      </Link>
                    </div>
                  </div>

                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-May-P2-01.jpg"
                        }
                        alt={"อัพเดทเร็ว จัดการง่าย ที่ไหนก็ทำได้"}
                      />
                      <div className="crad-body-date">May 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>จัดการง่าย ใช้งานได้ทุกที่ อัพเดทได้ทุกเวลา</label>
                      <span>อัพเดทเร็ว จัดการง่าย ที่ไหนก็ทำได้!</span>
                      <Link to="/articles-saleserv-manage">อ่านต่อ {">"}</Link>
                    </div>
                  </div>

                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-May-P3-01.jpg"
                        }
                        alt={"การจัดการการขายคืออะไร? ทำไมทุกองค์กรควรมี?"}
                      />
                      <div className="crad-body-date">April 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>การจัดการการขาย คืออะไร? ทำไมทุกองค์กรควรมี</label>
                      <span>
                        ปิดยอดขาย… ไม่ยาก เพียงแค่มี ‘ระบบการจัดการการขาย’ ที่ดี
                      </span>
                      <Link to="/articles-manage-sale">อ่านต่อ {">"}</Link>
                    </div>
                  </div>
                </CardBody>
              </SwiperSlide>

              <SwiperSlide>
                <CardBody>
                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-May-P4-01.jpg"
                        }
                        alt={
                          "Online to Offline” มิติใหม่แห่งการจัดการ Sale บนโลกออนไลน์ "
                        }
                      />
                      <div className="crad-body-date">May 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>
                        Offline to Online (O2O) บริหารงานขายบนโลกออนไลน์
                        ไม่ใช่เรื่องยาก
                      </label>
                      <span>
                        งานเอกสารหลบไป! ก้าวสู่โลกของการบริหารจัดการงานขายด้วย
                        'SaleServ'
                        เครื่องมือเพิ่มประสิทธิภาพงานขายให้มีประสิทธิภาพ สะดวก
                        อยู่ที่ไหนก็ใช้ได้
                      </span>
                      <Link to="/articles-offline-to-online">
                        อ่านต่อ {">"}
                      </Link>
                    </div>
                  </div>

                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-June-01-01.jpg"
                        }
                        alt={
                          "บอกลาวิธีบริหารการขายแบบเดิมๆ ด้วย Online Sale Management จาก SaleServ"
                        }
                      />
                      <div className="crad-body-date">June 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>
                        บอกลาวิธีบริหารการขายแบบเดิมๆ ด้วย Online Sales
                        Management
                      </label>
                      <span>
                        ธุรกิจที่อาศัย ‘การขาย’
                        ไม่ว่าจะขนาดเล็กหรือใหญ่ต้องมีระบบการจัดการงานขายที่ตอบโจทย์ความต้องการอย่างตรงจุด
                        รวดเร็ว และครอบคลุมส่วนต่างๆ อย่าง คลังสินค้า (Warehouse
                        Management) และระบบขายหน้าร้าน POS (พีโอเอส)
                        ผ่านระบบออนไลน์ หรือ Online Sales Management
                        ที่ช่วยลดขั้นตอนการทำงานของทีมขายและเพิ่มความสะดวกในการทำงาน
                      </span>
                      <Link to="/online-sales-management">อ่านต่อ {">"}</Link>
                    </div>
                  </div>

                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-June-02-01.jpg"
                        }
                        alt={
                          "ขายถึงไหน ปิดเท่าไหร่ จัดการยังไง Saleserv ช่วยได้"
                        }
                      />
                      <div className="crad-body-date">June 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>
                        ขายถึงไหน ปิดเท่าไหร่ จัดการยังไง Saleserv ช่วยได้
                      </label>
                      <span>
                        รู้ทันการทำงานของทีม Sales
                        บริหารจัดการงานขายอย่างคล่องตัวด้วย 'SaleServ'
                        ทธิภาพงานขายให้มีประสิทธิภาพ สะดวก อยู่ที่ไหนก็ใช้ได้
                      </span>
                      <Link to="/work-of-the-Sales-team">อ่านต่อ {">"}</Link>
                    </div>
                  </div>
                </CardBody>
              </SwiperSlide>

              <SwiperSlide>
                <CardBody>
                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-June-03-01.jpg"
                        }
                        alt={
                          "คำนวณ Commission ทันที อัปเดตยอดขาย Real - timeSaleServ เครื่องมือบริหารการขายสำหรับบริษัทยุคใหม่"
                        }
                      />
                      <div className="crad-body-date">June 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>
                        ทำความรู้จักกับ SaleServ
                        เครื่องมือบริหารการขายสำหรับบริษัทยุคใหม่
                      </label>
                      <span>
                        บริหารจัดการทีมขายได้อย่างสะดวก รวดเร็ว
                        ผ่านระบบออนไลน์ด้วย 'SaleServ'
                        ระบบการบริหารและจัดการการขายหน้าร้านค้าปลีกที่สามารถเก็บข้อมูลยอดขาย,
                        ตรวจสอบสต็อกสินค้า
                        และคำนวณค่าคอมมิชชันเพื่อวางแผนโปรโมชั่นการตลาดและพัฒนากลยุทธ์การขาย
                      </span>
                      <Link to="/high-light-saleserv">อ่านต่อ {">"}</Link>
                    </div>
                  </div>

                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-June-04-01.jpg"
                        }
                        alt={
                          "มัดรวมระบบ HR และระบบ Sale ไว้ในที่เดียวที่ SaleServe"
                        }
                      />
                      <div className="crad-body-date">June 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>
                        มัดรวมระบบ HR และระบบ Sale ไว้ในที่เดียวที่ 'SaleServe'
                      </label>
                      <span>
                        SaleServ ซอฟต์แวร์เพื่อการบริหารจัดการ ‘ทีมขาย’ และ
                        ‘งานขาย’ ให้มีประสิทธิภาพ
                      </span>
                      <Link to="/hr-saleserv">อ่านต่อ {">"}</Link>
                    </div>
                  </div>

                  <div></div>
                </CardBody>
              </SwiperSlide>
            </Swiper>
          </SwiperDesktop>

          <SwiperMoblie>
            <Swiper
              pagination={pagination}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide>
                <CardBody>
                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-May-P1-01.jpg"
                        }
                        alt={"เชื่อมโยงทุกหน้าที่ สร้างงานขายที่ดีกว่า"}
                      />
                      <div className="crad-body-date">May 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>
                        Better Sale Better Service
                        เครื่องมือช่วยเพิ่มประสิทธิภาพในการบริหารจัดการทีมขายและยอดขาย
                      </label>
                      <span>
                        'SaleServ' เพิ่มประสิทธิภาพการบริหารจัดการทีมขาย
                        เสริมสร้างยอดขายอย่างก้าวกระโดด
                      </span>
                      <Link to="/articles-better-sale-better-service">
                        อ่านต่อ {">"}
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </SwiperSlide>

              <SwiperSlide>
                <CardBody>
                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-May-P2-01.jpg"
                        }
                        alt={"อัพเดทเร็ว จัดการง่าย ที่ไหนก็ทำได้"}
                      />
                      <div className="crad-body-date">May 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>จัดการง่าย ใช้งานได้ทุกที่ อัพเดทได้ทุกเวลา</label>
                      <span>อัพเดทเร็ว จัดการง่าย ที่ไหนก็ทำได้!</span>
                      <Link to="/articles-saleserv-manage">อ่านต่อ {">"}</Link>
                    </div>
                  </div>
                </CardBody>
              </SwiperSlide>

              <SwiperSlide>
                <CardBody>
                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-May-P3-01.jpg"
                        }
                        alt={"การจัดการการขายคืออะไร? ทำไมทุกองค์กรควรมี?"}
                      />
                      <div className="crad-body-date">April 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>การจัดการการขาย คืออะไร? ทำไมทุกองค์กรควรมี</label>
                      <span>
                        ปิดยอดขาย… ไม่ยาก เพียงแค่มี ‘ระบบการจัดการการขาย’ ที่ดี
                      </span>
                      <Link to="/articles-manage-sale">อ่านต่อ {">"}</Link>
                    </div>
                  </div>
                </CardBody>
              </SwiperSlide>

              <SwiperSlide>
                <CardBody>
                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-May-P4-01.jpg"
                        }
                        alt={
                          "Online to Offline” มิติใหม่แห่งการจัดการ Sale บนโลกออนไลน์ "
                        }
                      />
                      <div className="crad-body-date">May 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>
                        Offline to Online (O2O) บริหารงานขายบนโลกออนไลน์
                        ไม่ใช่เรื่องยาก
                      </label>
                      <span>
                        งานเอกสารหลบไป! ก้าวสู่โลกของการบริหารจัดการงานขายด้วย
                        'SaleServ'
                        เครื่องมือเพิ่มประสิทธิภาพงานขายให้มีประสิทธิภาพ สะดวก
                        อยู่ที่ไหนก็ใช้ได้
                      </span>
                      <Link to="/articles-offline-to-online">
                        อ่านต่อ {">"}
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </SwiperSlide>

              <SwiperSlide>
                <CardBody>
                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-June-01-01.jpg"
                        }
                        alt={
                          "บอกลาวิธีบริหารการขายแบบเดิมๆ ด้วย Online Sale Management จาก SaleServ"
                        }
                      />
                      <div className="crad-body-date">June 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>
                        บอกลาวิธีบริหารการขายแบบเดิมๆ ด้วย Online Sales
                        Management
                      </label>
                      <span>
                        ธุรกิจที่อาศัย ‘การขาย’
                        ไม่ว่าจะขนาดเล็กหรือใหญ่ต้องมีระบบการจัดการงานขายที่ตอบโจทย์ความต้องการอย่างตรงจุด
                        รวดเร็ว และครอบคลุมส่วนต่างๆ อย่าง คลังสินค้า (Warehouse
                        Management) และระบบขายหน้าร้าน POS (พีโอเอส)
                        ผ่านระบบออนไลน์ หรือ Online Sales Management
                        ที่ช่วยลดขั้นตอนการทำงานของทีมขายและเพิ่มความสะดวกในการทำงาน
                      </span>
                      <Link to="/online-sales-management">อ่านต่อ {">"}</Link>
                    </div>
                  </div>
                </CardBody>
              </SwiperSlide>

              <SwiperSlide>
                <CardBody>
                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-June-02-01.jpg"
                        }
                        alt={
                          "ขายถึงไหน ปิดเท่าไหร่ จัดการยังไง Saleserv ช่วยได้"
                        }
                      />
                      <div className="crad-body-date">June 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>
                        ขายถึงไหน ปิดเท่าไหร่ จัดการยังไง Saleserv ช่วยได้
                      </label>
                      <span>
                        รู้ทันการทำงานของทีม Sales
                        บริหารจัดการงานขายอย่างคล่องตัวด้วย 'SaleServ'
                        ทธิภาพงานขายให้มีประสิทธิภาพ สะดวก อยู่ที่ไหนก็ใช้ได้
                      </span>
                      <Link to="/work-of-the-Sales-team">อ่านต่อ {">"}</Link>
                    </div>
                  </div>
                </CardBody>
              </SwiperSlide>

              <SwiperSlide>
                <CardBody>
                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-June-03-01.jpg"
                        }
                        alt={
                          "คำนวณ Commission ทันที อัปเดตยอดขาย Real - timeSaleServ เครื่องมือบริหารการขายสำหรับบริษัทยุคใหม่"
                        }
                      />
                      <div className="crad-body-date">June 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>
                        ทำความรู้จักกับ SaleServ
                        เครื่องมือบริหารการขายสำหรับบริษัทยุคใหม่
                      </label>
                      <span>
                        บริหารจัดการทีมขายได้อย่างสะดวก รวดเร็ว
                        ผ่านระบบออนไลน์ด้วย 'SaleServ'
                        ระบบการบริหารและจัดการการขายหน้าร้านค้าปลีกที่สามารถเก็บข้อมูลยอดขาย,
                        ตรวจสอบสต็อกสินค้า
                        และคำนวณค่าคอมมิชชันเพื่อวางแผนโปรโมชั่นการตลาดและพัฒนากลยุทธ์การขาย
                      </span>
                      <Link to="/high-light-saleserv">อ่านต่อ {">"}</Link>
                    </div>
                  </div>
                </CardBody>
              </SwiperSlide>

              <SwiperSlide>
                <CardBody>
                  <div>
                    <div className="crad-body-img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/Saleserv-June-04-01.jpg"
                        }
                        alt={
                          "มัดรวมระบบ HR และระบบ Sale ไว้ในที่เดียวที่ SaleServe"
                        }
                      />
                      <div className="crad-body-date">June 2022</div>
                    </div>
                    <div className="crad-body-text">
                      <label>
                        มัดรวมระบบ HR และระบบ Sale ไว้ในที่เดียวที่ 'SaleServe'
                      </label>
                      <span>
                        SaleServ ซอฟต์แวร์เพื่อการบริหารจัดการ ‘ทีมขาย’ และ
                        ‘งานขาย’ ให้มีประสิทธิภาพ
                      </span>
                      <Link to="/hr-saleserv">อ่านต่อ {">"}</Link>
                    </div>
                  </div>
                </CardBody>
              </SwiperSlide>
            </Swiper>
          </SwiperMoblie>
        </Contents>
      </div>
    </Background>
  );
};

export default Articles;
