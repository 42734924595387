import SalePage from "./page/SalePage";
import BatterSale from "./page/BatterSale";
import SaleServManage from "./page/SaleServManage";
import ManageSale from "./page/ManageSale";
import ManageOnline from "./page/ManageOnline";
import OnlineSalesManagement from "./page/OnlineSalesManagement";
import SaleHalp from "./page/SaleHalp";
import ProsSaleserv from "./page/ProsSaleserv";
import HrSaleserv from "./page/HrSaleserv";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import DocumentMeta from "react-document-meta";

function App() {

  const metaPgaeHead = {
    title:
      "SaleServ.co | เครื่องมือช่วยเพิ่มประสิทธิภาพเพื่อการบริหารจัดการทีมขายและยอดขายอย่างเป็นระบบ",
    description:
      "SaleServ เครื่องมือในการบริหารจัดการทีมขายและยอดขายให้มีประสิทธิภาพสูงสุด มีระบบที่ครอบคลุม เพื่อตอบโจทย์ภาคธุรกิจที่ต้องการดำเนินงานขายอย่างมีคุณภาพและสร้างความเติบโตอย่างมั่นคง",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Better Sale Better Service เครื่องมือช่วยเพิ่มประสิทธิภาพในการบริหารจัดการทีมขายและยอดขาย",
      },
    },
  };

  const metaPgae1 = {
    title:
      "Better Sale Better Service เครื่องมือช่วยเพิ่มประสิทธิภาพในการบริหารจัดการทีมขายและยอดขาย",
    description:
      "SaleServ เพิ่มประสิทธิภาพการบริหารจัดการทีมขาย เสริมสร้างยอดขายอย่างก้าวกระโดด บอกลาการจัดการ #ทีมขาย แบบเดิมๆ พร้อมก้าวสู่การบริหารแบบยุคใหม่ ภายใต้ระบบ PC Sale Solutions แพลตฟอร์มบริหารทีมขายที่จะช่วย ผู้บริหาร",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Better Sale Better Service เครื่องมือช่วยเพิ่มประสิทธิภาพในการบริหารจัดการทีมขายและยอดขาย",
      },
    },
  };

  const metaPgae2 = {
    title:
      "Better Sale Better Service จัดการง่าย ใช้งานได้ทุกที่ อัพเดทได้ทุกเวลา",
    description:
      "อัพเดทเร็ว จัดการง่าย ที่ไหนก็ทำได้ เข้ายุคใหม่ ใครๆ ก็ต้องเปลี่ยนมาใช้ SaleServ เครื่องมือช่วยเพิ่มประสิทธิภาพในการบริหารจัดการทีมขายและยอดขายได้อย่างสะดวกและมีประสิทธิภาพมากยิ่งขึ้น ",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "จัดการง่าย ใช้งานได้ทุกที่ อัพเดทได้ทุกเวลา",
      },
    },
  };

  const metaPgae3 = {
    title:
      "Better Sale Better Service จัดการง่าย ใช้งานได้ทุกที่ อัพเดทได้ทุกเวลา",
    description:
      "ปิดยอดขาย… ไม่ยาก เพียงแค่มี ‘ระบบการจัดการการขาย’ ที่ดี การจัดการการขาย’ คืออะไร",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "ปิดยอดขาย ไม่ยาก เพียงแค่มี ‘ระบบการจัดการการขาย’ ที่ดี",
      },
    },
  };

  const metaPgae4 = {
    title: "Offline to Online (O2O) บริหารงานขายบนโลกออนไลน์ ไม่ใช่เรื่องยาก",
    description:
      "งานเอกสารหลบไป! ก้าวสู่โลกของการบริหารจัดการงานขายด้วย ‘SaleServ’ เครื่องมือเพิ่มประสิทธิภาพงานขายให้มีประสิทธิภาพ สะดวก อยู่ที่ไหนก็ใช้ได้",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Offline to Online (O2O) บริหารงานขายบนโลกออนไลน์ ไม่ใช่เรื่องยาก",
      },
    },
  };

  const metaPgae5 = {
    title: "บอกลาวิธีบริหารการขายแบบเดิมๆ กับ Online Sales Management",
    description:
      "ธุรกิจที่อาศัย ‘การขาย’ ไม่ว่าจะขนาดเล็กหรือใหญ่ต้องมีระบบการจัดการงานขายที่ตอบโจทย์ความต้องการอย่างตรงจุด รวดเร็ว และครอบคลุมส่วนต่างๆ อย่าง คลังสินค้า (Warehouse Management) และระบบขายหน้าร้าน POS (พีโอเอส) ผ่านระบบออนไลน์ หรือ Online Sales Management ที่ช่วยลดขั้นตอนการทำงานของทีมขายและเพิ่มความสะดวกในการทำงาน ",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "บอกลาวิธีบริหารการขายแบบเดิมๆ ด้วย Online Sales Management",
      },
    },
  };

  const metaPgae6 = {
    title: "ขายถึงไหน ปิดเท่าไหร่ จัดการยังไง Saleserv ช่วยได้",
    description:
      "เปลี่ยนการทำงานแบบเดิมๆ ที่ยุ่งยาก ก้าวสู่การบริหารจัดการงานขายแบบรูปแบบใหม่ด้วย SaleServ เครื่องมือสำคัญที่จะช่วยให้ทุกๆ วันในการบริหารทีมขายของคุณดำเนินไปอย่างสะดวก รวดเร็ว เข้าถึงทุกข้อมูล ด้วยระบบที่่ส่งเสริม Sales Process Management และ Sales Team Management ที่จะช่วยคุณ",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "รู้ทันการทำงานของทีม Sales บริหารจัดการงานขายอย่างคล่องตัวด้วย ‘SaleServ’ ",
      },
    },
  };

  const metaPgae7 = {
    title: "ทำความรู้จักกับ SaleServ เครื่องมือบริหารการขายสำหรับบริษัทยุคใหม่",
    description:
      "บริหารจัดการทีมขายได้อย่างสะดวก รวดเร็ว ผ่านระบบออนไลน์ด้วย ‘SaleServ’ ระบบการบริหารและจัดการการขายหน้าร้านค้าปลีกที่สามารถเก็บข้อมูลยอดขาย, ตรวจสอบสต็อกสินค้า และคำนวณค่าคอมมิชชันเพื่อวางแผนโปรโมชั่นการตลาดและพัฒนากลยุทธ์การขาย",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "ทำความรู้จักกับ ‘SaleServ’ เครื่องมือบริหารการขายสำหรับบริษัทยุคใหม่",
      },
    },
  };

  const metaPgae8 = {
    title: "มัดรวมระบบ HR และระบบ Sale ไว้ในที่เดียวที่ ‘SaleServe’",
    description:
      "มัดรวมระบบ HR และระบบ Sale ไว้ในที่เดียว พัฒนาธุรกิจอย่างไม่หยุดยั้งด้วยเทคโนโลยีใหม่ๆ ที่ถูกพัฒนาเพื่อระบบ POS (Point of Sale) เพื่อช่วยอำนวยความสะดวกในการบริหารจัดการการขายหน้าร้านค้าปลีกเพื่อให้ตอบโจทย์ทุกความต้องการ",
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "SaleServ ซอฟต์แวร์เพื่อการบริหารจัดการ ‘ทีมขาย’ และ ‘งานขาย’ ให้มีประสิทธิภาพ",
      },
    },
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <DocumentMeta {...metaPgaeHead}>
            <SalePage />
          </DocumentMeta>
        } />
        <Route
          path="/articles-better-sale-better-service"
          element={
            <DocumentMeta {...metaPgae1}>
              <BatterSale />
            </DocumentMeta>
          }
        />
        <Route
          path="/articles-saleserv-manage"
          element={
            <DocumentMeta {...metaPgae2}>
              <SaleServManage />
            </DocumentMeta>
          }
        />
        <Route
          path="/articles-manage-sale"
          element={
            <DocumentMeta {...metaPgae3}>
              <ManageSale />
            </DocumentMeta>
          }
        />
        <Route
          path="/articles-offline-to-online"
          element={
            <DocumentMeta {...metaPgae4}>
              <ManageOnline />
            </DocumentMeta>
          }
        />
        <Route
          path="/online-sales-management"
          element={
            <DocumentMeta {...metaPgae5}>
              <OnlineSalesManagement />
            </DocumentMeta>
          }
        />
        <Route
          path="/work-of-the-Sales-team"
          element={
            <DocumentMeta {...metaPgae6}>
              <SaleHalp />
            </DocumentMeta>
          }
        />
        <Route
          path="/high-light-saleserv"
          element={
            <DocumentMeta {...metaPgae7}>
              <ProsSaleserv />
            </DocumentMeta>
          }
        />
        <Route
          path="/hr-saleserv"
          element={
            <DocumentMeta {...metaPgae8}>
              <HrSaleserv />
            </DocumentMeta>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
