import {
  Background,
  ContentsCrad,
  CardBody,
  Contenttext,
  ButtonContact,
} from "../styles/ContactUs";

const TapButton = () => {
  return (
    <Background>
      <div className="container-sm text-center">
        <Contenttext>
          <label className="lable-top">Contact Us</label>
          <label className="lable-center">
            SaleServ
            เครื่องมือที่จะช่วยบริหารทีมขายและยอดขายขององค์กรคุณให้มีประสิทธิภาพสูงสุด
          </label>
          <label className="label-bottom">
            ส่งข้อความติดต่อเราได้ที่นี่ หรือช่องทางการติดต่อด้านล่างนี้
          </label>
        </Contenttext>
        <ContentsCrad className="justify-content-center">
          <CardBody>
            <div className="contact-text ">
              <img
                src={process.env.PUBLIC_URL + "/images/contact-img.png"}
                alt={process.env.PUBLIC_URL + "/images/contact-img.png"}
                className="contact-img"
              />
            </div>
            <div className="contact-text">
              <img
                src={process.env.PUBLIC_URL + "/images/icon-contact.svg"}
                alt={process.env.PUBLIC_URL + "/images/icon-contact.svg"}
                className="icon-img"
              />
              <div >
                <div>02-0072421</div>
                <div>@Kencafe</div>
                <div style={{ fontWeight: 400, fontSize: 12, marginTop: 5 }}>
                  KEN Corporation Co.,Ltd
                  บริษัท เคนคอร์ปอเรชั่น จำกัด (สำนักงานใหญ่)
                </div>
                <div style={{ fontWeight: 400, fontSize: 12, marginTop: 8 }}>
                  24th Floor, Chamchuri Square,
                  319 Phayathai Road, Kwaeng Pathumwan,
                  Khet Pathum wan, Bangkok 10330
                </div>
              </div>
              <ButtonContact onClick={() => window.open("http://m.me/ITSolutionCafe")}>ติดต่อเรา</ButtonContact>
            </div>
          </CardBody>
        </ContentsCrad>
      </div>
    </Background >
  );
};

export default TapButton;
