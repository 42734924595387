import { Contents, CardStyle, CardImage, CardText } from "../styles/ProductStyle";

const Products = () => {

  return (

    <div className="container-sm" >
      <Contents>
        <div>
          <label className="lable-top">Products</label>
          <label className="lable-text">
            มัดรวมระบบ HR และระบบ Sale ไว้ในที่เดียว ที่ 'SaleServe'
          </label>
          <span style={{ fontFamily: "Mitr" }}>
            <label className="lable-bottom">SALESERV </label>{" "}
            เราพัฒนาและออกแบบมาเพื่อให้การจัดการการขาย และบริหารทีมขายได้
            อย่างสะดวก มีประสิทธิภาพมากยิ่งขึ้น ช่วยให้การวางแผน
            และการจัดการธุรกิจเติบโต ทันยุคสมัยในปัจจุบัน มาพร้อม Function
            ที่ตอบโจทย์เรื่องการขาย
          </span>
        </div>
        <div>
          <CardStyle>
            <CardImage>
              <div className="img-ro">
                <img
                  src={process.env.PUBLIC_URL + "/images/clock-icon.svg"}
                  alt={process.env.PUBLIC_URL + "/images/clock-icon.svg"}
                />
              </div>
            </CardImage>
            <CardText>
              <label>อัปเดตยอดขาย Real - time</label>
              <span>
                สามาถดูยอดขายจากทีมขายได้เลยทันที เมื่อมีการขายสินค้าเกิดขึ้น
                วิเคราะห์แผนการขายได้แบบไม่ต้องรอ
              </span>
            </CardText>
          </CardStyle>
          <CardStyle>
            <CardImage>
              <div className="img-red">
                <img
                  src={process.env.PUBLIC_URL + "/images/person-icon.svg"}
                  alt={process.env.PUBLIC_URL + "/images/person-icon.svg"}
                />
              </div>
            </CardImage>
            <CardText>
              <label>บริหารทีมได้อย่างมีประสิทธิภาพ</label>
              <span>
                ตรวจสอบจำนวนทีมขายและวางแผนได้ทันทีเมื่อเกิดปัญหาสามารถ
                เช็คทีม และสาขาได้ทันที ช่วยให้จัดการทีมได้ง่ายขึ้น
              </span>
            </CardText>
          </CardStyle>
          <CardStyle>
            <CardImage>
              <div className="img-pring">
                <img
                  src={process.env.PUBLIC_URL + "/images/hand-icon.svg"}
                  alt={process.env.PUBLIC_URL + "/images/hand-icon.svg"}
                />
              </div>
            </CardImage>
            <CardText>
              <label>คำนวณ Commission ทันที</label>
              <span>
                ทุกการขายไม่ต้องรอคำนวณผลตอบแทนให้เสียเวลา สามารถคำนวณ
                และตรวจสอบยอดที่ต้องทำเพื่อให้ถึงเป้าที่กำหนด
              </span>
            </CardText>
          </CardStyle>
          <CardStyle>
            <CardImage>
              <div className="img-blue">
                <img
                  src={process.env.PUBLIC_URL + "/images/wifi-icon.svg"}
                  alt={process.env.PUBLIC_URL + "/images/wifi-icon.svg"}
                />
              </div>
            </CardImage>
            <CardText>
              <label>บอกลาการทำงานแบบ Offline</label>
              <span>
                ครบทุก Function ให้ที่เดียว ทั้งเรื่องการจัดการทีมขายยอดขาย
                ของทีม และการเข้าทำงานที่สามารถตรวจสอบได้ทันทีรวมถึงสรุป
                ผลการทำงานประจำวัน
              </span>
            </CardText>
          </CardStyle>
        </div>
      </Contents>
    </div>

  );
};

export default Products;
