import { Contents, Background } from "../styles/ContentPage";
import Navbar from "../componentes/Navbar";
import Footer from "../componentes/Footer";
import { useNavigate } from "react-router-dom";
import { BsFillBrightnessAltHighFill } from "react-icons/bs";
import { FaHandPointDown } from "react-icons/fa";
import { GiRotaryPhone } from "react-icons/gi";
import { BiWorld } from "react-icons/bi";

const ManageOnline = () => {
  const navigate = useNavigate();
  const onChangeHandle = () => {
    navigate("/");
  };
  window.scrollTo(0, 0)
  return (
    <Background>
      <Navbar onChangeHandle={onChangeHandle} />
      <div className="container-sm">
        <Contents>
          <label className="label-header">
            Offline to Online (O2O) บริหารงานขายบนโลกออนไลน์ ไม่ใช่เรื่องยาก
          </label>
          <div className="content">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/images/Saleserv-May-P4-01.jpg"}
                alt={"การจัดการการขายคืออะไร? ทำไมทุกองค์กรควรมี"}
              />
            </div>
            <div>
              <label style={{ marginBottom: 15 }}>
                งานเอกสารหลบไป! ก้าวสู่โลกของการบริหารจัดการงานขายด้วย
                'SaleServ' เครื่องมือเพิ่มประสิทธิภาพงานขายให้มีประสิทธิภาพ
                สะดวก อยู่ที่ไหนก็ใช้ได้
              </label>
              <label style={{ marginBottom: 15 }}>
                SaleServ
                เว็บแอปที่ถูกพัฒนาเพื่อให้ตอบโจทย์ทุกความต้องการในทุกธุรกิจการขายด้วยการใช้เทคโนโลยีใหม่ๆ
                ทำให้เกิดระบบ one stop service solution
                ที่สามารถติดตามการทำงานของทีมเซลล์ คำนวณยอดขายและค่าคอมมิชชัน
                วัดผลการทำงานและการขายอย่างแม่นยำ ที่สำคัญใช้งานง่าย สะดวก
                อยู่ที่ไหนก็ทำได้ เพื่อการบริหารจัดการทีม Sales โดยเฉพาะ
              </label>
              <label style={{ marginBottom: 15 }}>
                ปิดยอดขายให้ขึ้นเป้า
                สร้างรายได้ให้ธุรกิจของคุณก้าวไปข้างหน้าอย่างไม่หยุดยั้งด้วยเครื่องมือเสริมประสิทธิภาพในการทำงานและการจัดการทีมขายด้วย
                SaleServ
              </label>
              <label style={{ marginBottom: 15 }}>
                Better Sales, Better Service
              </label>

              <label>
                ติดต่อสอบถามเพิ่มเติมได้ที่ <FaHandPointDown color="#d1ad8e" />
              </label>
              <label>
                <BsFillBrightnessAltHighFill /> Inbox : m.me/SaleServ.co
              </label>
              <label>
                <GiRotaryPhone color="#b50d1b" /> Tel : 02-0072421
              </label>
              <label style={{ marginBottom: 15 }}>
                <BiWorld color="#0fad4c" /> Website:{" "}
                <a href=" https://SaleServ.co">https://SaleServ.co</a>
              </label>
              <label>
                #SaleServ #เว็บไซต์ #การจัดการทีมขาย #BetterSalesBetterService #ทีมขาย
              </label>
            </div>
          </div>
        </Contents>
      </div>
      <Footer />
    </Background>
  );
};

export default ManageOnline;
