import { Background, Contents } from "../styles/AboutUsStyle";

const AboutUs = () => {
  return (
    <Background>
      <div className="container-sm">
        <Contents>
          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/abuot-img.svg"}
              alt={process.env.PUBLIC_URL + "/images/abuot-img.svg"}
            />
          </div>
          <div>
            <label className="label-top">About Us</label>
            <label className="label-center">
              SaleServ เครื่องมือในการบริหารจัดการ
              ทีมขายและยอดขายให้มีประสิทธิภาพสูงสุด
            </label>
            <div className="label-bottom">
              <span>SaleServ </span>
              <span style={{ fontFamily: "Mitr" }}>
                เกิดจากแนวคิดและความต้องการในการจัดระเบียบข้อมูลและพัฒนาระบบการ
                ทำงานของทีมขาย (Sale team) บนเว็บไซต์สำเร็จรูปที่มีคุณภาพ
                ใช้งานสะดวก และมี ระบบที่ครอบคลุม
                เพื่อตอบโจทย์ภาคธุรกิจที่ต้องการดำเนินงานขายอย่างมีคุณภาพและ
                สร้างความเติบโตอย่างมั่นคง
              </span>
            </div>
          </div>
        </Contents>
      </div>
    </Background>
  );
};

export default AboutUs;
