import { Background, Contents, ButtonTap } from "../styles/TapButtonStyle";

const TapButton = () => {
  return (
    <Background>
      <div className="container-sm">
        <Contents>
          <div>
            <label className="label-top">Get In Touch</label>
            <label className="label-bottom">
              มาเริ่มสร้างงานกับเรา SaleServ
            </label>
          </div>
          <div>
            <ButtonTap onClick={() => window.open("http://m.me/ITSolutionCafe")}>เริ่มงานกับเรา</ButtonTap>
          </div>
        </Contents>
      </div>
    </Background>
  );
};

export default TapButton;
